<template>
    <v-dialog v-model="otevreno" width="600" :fullscreen="$vuetify.breakpoint.mobile">

        <v-card class="">

            <div class="text-overline pt-5" style="text-align:center">Zrušení dvoufázového ověření</div>
            
            <div class="body-1 font-weight-light pa-5" style="text-align:center">
              <p>Tímto krokem zrušíte dvoufázové ověření a snížíte úroveň zabezpeční <br> přístupu do portálu Connect plus s.r.o.</p>
              <br>
              <p>Opravdu chcete zrušit doufázové ověření?</p>
            </div>

            <v-form class="pa-4" ref="myForm">



              <v-btn v-if="!odesilani" @click='odeslat' block>Ano zrušit</v-btn>
              <div v-if="odesilani" block style="text-align:center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-form>
            <div class="text-overline pb-4" style="text-align:center" @click="otevreno=false"><a>Zavřít</a></div>
      </v-card>

    </v-dialog>
</template>

<script>

 export default {
    props: ['zobraz','udaje'],
    watch: {
      zobraz() {
        this.otevreno = true
        this.potvrzeni = ""
        this.u = this.udaje
      },

    },
    data: () => ({
      odesilani: false,
      otevreno: false,
      u: {},
     }),
    
    methods: {
      odeslat() {
        if (this.$refs.myForm.validate()) {
                this.odesilani = true
                let url = process.env.VUE_APP_API_URL + "/odeberTOTP"

                let options = {
                    credentials: 'include',
                    headers: {
                    'Authorization': localStorage.getItem("token"),
                    },
                }

                fetch(url, options)
                    .then(res => {
                      if (res.status !== 200) {
                          throw new Error("Požadavek se nepodařilo odeslat.")
                      }
                      return res.json()
                    })
                    .then(() => {
                      // this.$emit('zavrit')
                      this.otevreno = false
                      this.$root.$emit('uspech', "Dvoufázové ověření bylo odebráno.")
                      this.odesilani = false
                      this.u.TOTP = false
                    })
                    .catch((err) => {
                        this.$root.$emit('chyba', err.message)
                        this.odesilani = false
                    })

        }
      }
    }

 }
</script>

