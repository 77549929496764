<template>
  <v-dialog v-model="otevreno" fullscreen persistent hide-overlay>
    <v-card tile class="d-flex justify-center align-center">
      <v-card tile class="pa-2" style="min-width: 20em">
        <div>
          <v-img
            :src="require('../assets/logoConnect.svg')"
            class="my-3"
            contain
            height="200"
          />
        </div>

        <div class="text-overline" style="text-align: center">
          Zákaznický portál
        </div>

        <v-form class="pa-4">
          <v-text-field
            v-model="jmeno"
            hint="Přihlašovací jméno do portálu"
            label="Jméno"
            autofocus

          ></v-text-field>

          <v-text-field
            v-model="heslo"
            hint="Vaše soukromé heslo do portálu"
            label="Heslo"
            :type="ukazHeslo ? 'text' : 'password'"

            :append-icon="ukazHeslo ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="ukazHeslo = !ukazHeslo"
          ></v-text-field>
          <v-spacer class="pa-4"></v-spacer>
          <v-btn @click="odeslat" block>Příhlásit se</v-btn>
        </v-form>

        <div
          class="text-overline"
          style="text-align: center"
          @click="registershow = true"
        >
          <a>Nová registrace</a>
        </div>
        <div
          class="text-overline"
          style="text-align: center"
          @click="renewshow = true"
        >
          <a>Zapomenuté heslo</a>
        </div>
      </v-card>
    </v-card>

    <p-form-register
      v-if="registershow"
      @zavrit="registershow = false"
    ></p-form-register>
    <p-form-renewpass
      v-if="renewshow"
      @zavrit="renewshow = false"
    ></p-form-renewpass>
    <p-form-totp v-if="totp" @zavrit="totp = false" :jmeno="jmeno" :heslo="heslo"></p-form-totp>
  </v-dialog>
</template>

<script>
export default {

  data: () => ({
    otevreno: true,
    ukazHeslo: false,
    renewshow: false,
    registershow: false,
    totp: false,
    jmeno: "",
    heslo: "",
  }),

  methods: {
    odeslat() {

      let url = process.env.VUE_APP_API_URL + "/login";
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ jmeno: this.jmeno, heslo: this.heslo }),
      };

      fetch(url, options)
        .then((res) => {
          if (res.status !== 200) {
            window.location.href = "/";
            throw new Error("Přihlášení se nepodařilo");
          }
          return res.json();
        })
        .then((dat) => {
          if (dat.info == "Zadejte TOTP") {
                this.totp = true;
                // console.log("Oteviram TOTP");
                return
          }

          let tok = dat.token;
          localStorage.setItem("token", tok);
          this.$emit("zavrit");
          this.$root.$emit("uspech", "Přihlášení se podařilo");
        })
        .catch((err) => {
          this.$root.$emit("chyba", err.message);
        });
    },
  },
};
</script>
