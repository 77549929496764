<template>
    <v-dialog v-model="otevreno" width="600" :fullscreen="$vuetify.breakpoint.mobile">

        <v-card class=""  v-if="url == ''">

            <div class="text-overline pt-5" style="text-align:center">Nastavení dvoufázového ověření</div>
            
            <div class="body-1 font-weight-light pa-5" style="text-align:center">
              <p>Tímto krokem nastavíte vyšší zabezpeční<br> přístupu do portálu Connect plus s.r.o.</p>
              <p>Před aktivací si připravte/nainstalujte<br> aplikaci autentifikátor na Váš mobilní telefon.</p>
              <p>Můžete použít například:
                <br>
                <v-icon left>mdi-android</v-icon>
                <br><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_new">Google Authenticator - Android</a>
                <br><a href="https://play.google.com/store/apps/details?id=com.twofasapp" target="_new">2FA Authenticator (2FAS) - Android</a>
                <br><a href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_new">Microsoft Authenticator - Android</a>
                <br>
                <v-icon left>mdi-apple</v-icon>
                <br><a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_new">Google Authenticator - iOS</a>
                <br><a href="https://apps.apple.com/us/app/2fa-authenticator-2fas/id1217793794" target="_new">2FA Authenticator (2FAS) - iOS</a>
                <br><a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_new">Microsoft Authenticator - iOS</a>

              </p>
            </div>

            <v-form class="pa-4" ref="myForm">

              <v-spacer class="pa-4"></v-spacer>

              <v-btn v-if="!odesilani" @click='odeslat' block>Aktivovat</v-btn>
              <div v-if="odesilani" block style="text-align:center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-form>
            <div class="text-overline pb-4" style="text-align:center" @click="otevreno=false"><a>Zavřít</a></div>
      </v-card>


      <v-card  class="" v-if="url">
            <div class="text-overline pt-5" style="text-align:center">Dokončení dvoufázového ověření</div>

            <div class="body-1 font-weight-light pa-5" style="text-align:center">
            <p>Otevřete aplikaci autentifikátor a naskenujte tento QR kód. <br><b style="color:orange">Je důležité si tento kód uložit</b>, protože jinak už nebude <br>možné se bezpečně přihlásit do vašeho účtu.</p>
            </div>

            <div class="body-1 font-weight-light pa-5" style="text-align:center">
              <qrcanvas :options="{cellSize: 6, data: url}"></qrcanvas>
            </div>

            <div class="body-1 font-weight-light pa-5" style="text-align:center">
            <p>Nebo si zapište tento kód do Vaší aplikce ručně: <br><b> {{ this.kod }} </b></p>
            </div>


            <div class="text-overline pb-4" style="text-align:center" @click="otevreno=false"><a>Hotovo</a></div>
      </v-card>

    </v-dialog>
</template>

<script>
 const { QRCanvas } = require('qrcanvas-vue');

 export default {
    props: ['zobraz', 'udaje'],
    watch: {
      zobraz() {
        this.otevreno = true
        this.potvrzeni = ""
        this.u = this.udaje
      },
    },
    components: {
      qrcanvas: QRCanvas,
    },
    data: () => ({
      odesilani: false,
      otevreno: false,
      potvrzeni: "",
      url: "",
      kod: "",
      u: {},
     }),
    
    methods: {
      odeslat() {
        if (this.$refs.myForm.validate()) {
                this.odesilani = true
                let url = process.env.VUE_APP_API_URL + "/noveTOTP"

                let options = {
                    credentials: 'include',
                    headers: {
                    'Authorization': localStorage.getItem("token"),
                    },
                }

                fetch(url, options)
                    .then(res => {
                      if (res.status !== 200) {
                          throw new Error("Požadavek se nepodařilo odeslat.")
                      }
                      return res.json()
                    })
                    .then((dat) => {
                      // this.$emit('zavrit')
                      // this.otevreno = false
                      this.$root.$emit('uspech', "Dvoufázové ověření bylo úspěšně aktivováno.")
                      this.url = dat.totp.Url
                      this.kod = dat.totp.Kod
                      this.odesilani = false
                      this.u.TOTP = true
                    })
                    .catch((err) => {
                        this.$root.$emit('chyba', err.message)
                        this.odesilani = false
                    })

        }
      }
    }

 }
</script>

