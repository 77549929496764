<template>
  <v-dialog v-model="otevreno" fullscreen persistent hide-overlay>
    <v-card tile class="d-flex justify-center align-center">
      <v-card tile class="pa-2" style="min-width: 20em">
        <div>
          <v-img
            :src="require('../assets/logoConnect.svg')"
            class="my-3"
            contain
            height="200"
          />
        </div>

        <div class="text-overline" style="text-align: center">
          Dvoufaktorové ověření
        </div>

        <!-- <div class="body-1 font-weight-light" style="text-align: center">
          <p>
            Použijte autentifikátor ve svém <br />mobilním telefonu <br />a
            opište z něj šestimístný kód.
          </p>
        </div> -->

        <div class="body-1 font-weight-light" style="text-align: center">
          <!-- <p>Pokud se přihlášení nepodaří, kontaktujte <br>
              dispečink na tel.: <b>234 222 222</b></p> -->
          Použijte autentifikátor ve svém <br />mobilním telefonu <br />a
          opište z něj šestimístný kód.
        </div>

        <v-form class="pa-4" ref="regForm">
          <v-text-field
            v-model="kod"
            hint="Zadejte šestimístný kód."
            label="Kód"
            :rules="ruleKod"
            autofocus

            v-on:keydown.enter.prevent

          ></v-text-field>
          <v-spacer class="pa-4"></v-spacer>

          <v-btn v-if="!odesilani" @click="totp" block>Odeslat</v-btn>
          <div v-if="odesilani" block style="text-align: center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-form>
        <div
          class="text-overline"
          style="text-align: center"
          @click="$emit('zavrit')"
        >
          <a>Zavřít</a>
        </div>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['jmeno','heslo'],

  data: () => ({
    odesilani: false,
    otevreno: true,
    kod: "",

    ruleKod: [
      (value) => !!value || "Musí být vyplněno.",
      (value) => {
        const pattern = /^[0-9]{6}$/;
        return (
          pattern.test(value) || "Číslo zadejte ve tvaru: 123456 bez mezer."
        );
      },
    ],
  }),

  methods: {
    err() {
      throw "KeyDown"
    },

    totp() {

      if (this.$refs.regForm.validate()) {
        this.odesilani = true;
        let url = process.env.VUE_APP_API_URL + "/totp";
        let options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            jmeno: this.jmeno,
            heslo: this.heslo,
            totp: this.kod,
          }),
        };


        fetch(url, options)
          .then((res) => {

            if (res.status !== 200) {
              throw new Error(
                "Přihlášení se nepodařilo, zkuste zadat kód znovu."
              );
            }
            return res.json();
          })
          .then((dat) => {

            let tok = dat.token;
            localStorage.setItem("token", tok);

            window.location.href = "/";
            this.$emit("zavrit");
            this.$root.$emit("uspech", "Přihlášení se podařilo");
          })
          .catch((err) => {
            this.$root.$emit("chyba", err.message);
            this.odesilani = false;
          });
      }
    },
  },
};
</script>
