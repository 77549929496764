<template>
  <div>
    
    <!-- <v-container>
    <v-row class="text-center">
      <v-col><h2>Služba - internet</h2></v-col>
    </v-row>
    </v-container>

    <br> -->
    <v-container>
    <v-row class="text-center">
      <v-col>
              <v-btn
                color="blue"
                rounded
                outlined
                @click="show_form ++; internet_id=0; dostupna_rychlost=dostupnaRychlost()"
              >
                <v-icon left>mdi-plus-circle</v-icon>
                <span>Zřídit novou službu internet</span>
              </v-btn>
      </v-col>
    </v-row>
    </v-container>

        <v-row justify="center">
        <v-col xs="12" md="4" v-for="i in filtruj()" :key="i.Id">
        <v-card class="mt-6" :disabled="getStavDisable(i.Stav)">
          <v-app-bar
            flat
            color="gray lighten-5"
          >
 
            <v-toolbar-title class="text-h6 pl-0">
              Internet: {{ i.Download }} Mbit/s
            </v-toolbar-title>

            <v-spacer></v-spacer>

            ID:{{ i.Id }}<v-icon> {{ getTechnologieIcon(i.Technologie) }}</v-icon>
           </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>

              <v-list-item-subtitle class="mb-4">
                {{i.AdresaInstalace}}
                <span v-if="i.Byt"><br>Byt/místnost: {{ i.Byt }}</span>
              </v-list-item-subtitle>

              <div class="text-overline mb-4">
                  Stav: <span :class="getStavColor(i.Stav)" class="font-weight-light">{{ getStav(i.Stav) }}</span>
              </div>

              <div class="text-overline mb-4"  v-if="i.SmlouvaKod">
                Číslo smlouvy: <span class="font-weight-light">{{ i.SmlouvaKod }}</span>
              </div>

              <!-- <div class="text-overline mb-4"  v-if="i.Id">
                ID služby: <span class="font-weight-light">{{ i.Id }}</span>
              </div> -->


              <div class="text-overline mb-4">
                Rychlost: <span class="font-weight-light">{{ i.Download }}/{{ i.Upload }} Mbit/s</span>
                <!-- <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }"><v-icon color="primary" v-bind="attrs" v-on="on">mdi-information</v-icon></template>
                    <span>Stahování / nahrávání v Mbit/s</span>
                </v-tooltip> -->
              </div>

              <div class="text-overline mb-4" v-if="i.ZavazekDo">
                Závazek do: <span class="font-weight-light">{{ i.ZavazekDo }}</span>
              </div>


              <div class="text-overline mb-4" v-if="i.VerejnaIP">
                Veřejná IP adresa: <span class="font-weight-light">{{ i.VerejnaIP }}</span>
              </div>

              <div class="text-overline mb-4" v-if="i.Technologie">
                Technologie připojení: <span class="font-weight-light">{{ getTechnologie(i.Technologie) }}</span>
              </div>

              <div class="text-overline mb-4"  v-if="i.Kategorie">
                Režim: <span class="font-weight-light">{{ getKategorie(i.Kategorie) }}</span>
              </div>




            </v-list-item-content>
            <!-- <v-list-item-content> -->
            
            <!-- </v-list-item-content> -->
        </v-list-item>

        <v-card-actions>
              <v-btn
                color="blue"
                rounded
                outlined
                @click="show_form ++; internet_id=i.Id; dostupna_rychlost=dostupnaRychlost(i.Technologie);stav=i.Stav"
              >
                <v-icon left>mdi-wrench</v-icon>
                <span>Upravit paramery služby</span>
              </v-btn>
        </v-card-actions>

        <v-card-actions>
              <v-btn
                v-if="!i.OKU"
                color="blue"
                rounded
                outlined
                @click="loadOKU(i.Id);"
              >
                <v-icon left>mdi-eye</v-icon>
                <span>Zobrazit OKU kód</span>
              </v-btn>

              <div class="text-overline mb-4" v-if="i.OKU">
                OKU kód: <span class="font-weight-light">{{ i.OKU }}</span>
              </div>

        </v-card-actions>

        <!-- <v-card-actions>
              <v-btn
                color="orange"
                rounded
                outlined
              >
                <v-icon left>mdi-cog</v-icon>
                <span>Ověřit stav služby</span>
              </v-btn>
        </v-card-actions> -->

        </v-card>
        </v-col>

        </v-row>

    <p-form-change-internet :zobraz="show_form" :internet_id="internet_id" :dostupna_rychlost="dostupna_rychlost" :stav="stav" :udaje="internet"></p-form-change-internet>
  </div>
</template>

<script>

  export default {
    name: 'InternetView',
    data: () => ({
        internet: [],
        internet_id: 0,
        show_form: 0,
        dostupna_rychlost: [],
        // aktivni: false,
        loading: true,
        stav: ""
    }),

    mounted() {
      this.loadInternet()
    },

    methods: {
        reload() {
            this.loadInternet()
        },

        loadOKU(intid) {
            let url = process.env.VUE_APP_API_URL + "/oku/" + intid
            let options = {
                credentials: 'include',
                headers: {
                'Authorization': localStorage.getItem("token"),
                },
            }
            fetch(url, options)
                .then(res => {
                if (res.status === 401) {
                    this.$root.$emit('neprihlasen')
                    throw new Error("Uživatel odhlášen")
                }
                if (res.status !== 200) {
                    throw new Error("Chyba zpracování požadavku")
                }
                return res.json()
                })
                .then(dat => {
                  this.internet.find((o, i) => {
                    if (o.Id === intid) {
                      this.$set(this.internet[i], "OKU", dat.oku.OKU)
                    }
                  })
                })
                .catch((err) => {
                    this.$root.$emit('chyba', err.message)
                })
        },

        loadInternet() {
            let url = process.env.VUE_APP_API_URL + "/internet"
            let options = {
                credentials: 'include',
                headers: {
                'Authorization': localStorage.getItem("token"),
                },
            }
            fetch(url, options)
                .then(res => {
                if (res.status === 401) {
                    this.$root.$emit('neprihlasen')
                    throw new Error("Uživatel odhlášen")
                }
                if (res.status !== 200) {
                    throw new Error("Chyba zpracování požadavku")
                }
                return res.json()
                })
                .then(dat => {
                if (dat.internet) dat.internet.sort((a,b) => {if (a.Stav < b.Stav) return 1; else return -1})
                this.internet = dat.internet
                this.loading = false
                })
                .catch((err) => {
                    this.$root.$emit('chyba', err.message)
                })
        },

        filtruj() {
            if (!this.internet) return []
            return this.internet
        },

        getStavColor(stav) {
            if (stav === "ONline") return 'green--text'
            if (stav === "OFFline") return 'red--text'
            return 'orange--text'
        },

        getStavDisable(stav) {
          if (stav === "ONline") return false
          if (stav === "Pauza") return false
          return true
        },

        getStav(stav) {
            if (stav === "ONline") return 'Aktivní'
            if (stav === "OFFline") return 'Vypnuto'
            if (stav === "Storno") return 'Zrušeno'
            if (stav === "Pauza") return 'Pozastaveno'
            if (stav === "Neplatič") return 'Pozastaveno pro neplacení'
            return 'Stav služby se zjišťuje'
        },

        getKategorie(kategorie) {
            if (kategorie === "Firma") return "Přípojka pro firemní použití"
            if (kategorie === "Domactnost") return "Přípojka pro domácí použití"
            if (kategorie === "VIP domactnost") return "Přípojka VIP pro domácí použití"
            if (kategorie === "VIP Firma") return "Přípojka VIP pro firemní použití"
            if (kategorie === "L2 okruh") return "Pronajatý L2 okruh"
            if (kategorie === "VIP L2 okruh") return "VIP pronajatý L2 okruh"
            return ""
        },

        getTechnologieIcon(technologie) {
          if (technologie === "utp") return "mdi-ethernet-cable"
          if (technologie === "vdsl") return "mdi-router-network"
          if (technologie === "wifi") return "mdi-antenna"
          if (technologie === "ftth") return "mdi-laser-pointer"
          if (technologie === "fttb") return "mdi-cable-data"
          if (technologie === "10g") return "mdi-satellite-uplink"
          if (technologie === "17g") return "mdi-satellite-uplink"
          if (technologie === "60g") return "mdi-satellite-uplink"
          if (technologie === "gpon-hua") return "mdi-laser-pointer"
          if (technologie === "gpon-ubnt") return "mdi-laser-pointer"
          if (technologie === "koax") return "mdi-router-network"
          return "mdi-web"
        },

        getTechnologie(technologie) {
          if (technologie === "utp") return "UTP datový kabel"
          if (technologie === "vdsl") return "VDSL modem na telefonním vedení"
          if (technologie === "wifi") return "WiFi"
          if (technologie === "ftth") return "FTTH - optika do bytu"
          if (technologie === "fttb") return "FTTB - optika do budovy"
          if (technologie === "10g") return "10GHz bezdrát"
          if (technologie === "17g") return "17GHz bezdrát"
          if (technologie === "60g") return "60GHz WiFi"
          if (technologie === "gpon-hua") return "GPON - optický konvertor"
          if (technologie === "gpon-ubnt") return "GPON - optický konvertor"
          if (technologie === "koax") return ""
          return ""
        },

        dostupnaRychlost(technologie) {
          if (technologie === "utp") return ["1000/1000", "500/500", "400/400", "300/300", "200/200", "150/150", "100/100"]
          if (technologie === "vdsl") return ["250/25", "100/20", "50/10", "20/2"]
          if (technologie === "wifi") return ["40/10", "25/5"]
          if (technologie === "ftth") return ["1000/1000", "500/500", "400/400", "300/300", "200/200", "150/150", "100/100"]
          if (technologie === "fttb") return ["500/500", "400/400", "300/300", "200/200", "150/150", "100/100"]
          // if (technologie === "10g") return "mdi-satellite-uplink"
          // if (technologie === "17g") return "mdi-satellite-uplink"
          if (technologie === "60g") return ["200/50", "100/20", "40/10"]
          if (technologie === "gpon-hua") return ["1000/1000", "500/500", "400/400", "300/300", "200/200", "150/150", "100/100"]
          if (technologie === "gpon-ubnt") return ["1000/1000", "500/500", "400/400", "300/300", "200/200", "150/150", "100/100"]
          if (technologie === "koax") return ["300/80", "200/60", "100/40"]
          return ["300/300", "200/200", "100/100","50/50","40/10"]
        },
    }

  }
</script>
