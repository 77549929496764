<template>
  <div>
    
    <!-- <v-container>
    <v-row class="text-center">
      <v-col><h2>Služba - internet</h2></v-col>
    </v-row>
    </v-container>

    <br> -->

        <v-row justify="center">
        <v-col xs="12" md="4">
        <v-card class="mt-6">
          <v-app-bar
            flat
            color="gray lighten-5"
          >
 
            <v-toolbar-title class="text-h6 pl-0">
              Detail uživatele
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- <v-icon>mdi-television</v-icon> -->
            <!-- <img src="../assets/itv.png"> -->
           </v-app-bar>
          <v-list-item three-line>
            <v-list-item-content>

              <div class="text-overline mb-4"  v-if="udaje.Nazev">
                Název: <span class="font-weight-light">{{ udaje.Nazev }}</span>
              </div>
              <div class="text-overline mb-4"  v-if="udaje.Adresa">
                Adresa: <span class="font-weight-light">{{ udaje.Adresa }}</span>
              </div>
              <div class="text-overline mb-4"  v-if="udaje.Email">
                Email: <span class="font-weight-light">{{ udaje.Email }}</span>
              </div>
              <div class="text-overline mb-4"  v-if="udaje.Telefon">
                Telefon: <span class="font-weight-light">{{ udaje.Telefon }}</span>
              </div>
              <div class="text-overline mb-4"  v-if="udaje.Ico">
                IČO: <span class="font-weight-light">{{ udaje.Ico }}</span>
              </div>
              <div class="text-overline mb-4"  v-if="udaje.Novinky">
                Zasílat novinky na email: <span class="font-weight-light">{{ (udaje.Novinky == -1) ? 'Ne' : 'Ano' }}</span>
              </div>
              <div class="text-overline mb-4">
                Dvoufázové ověření: <span class="font-weight-light">{{ (udaje.TOTP) ? 'Ano' : 'Ne' }}</span>
              </div>

            </v-list-item-content>
            <!-- <v-list-item-content> -->
            
            <!-- </v-list-item-content> -->
        </v-list-item>

        <v-card-actions>
              <v-btn
                color="blue"
                rounded
                outlined
                @click="show_form ++"
              >
                <v-icon left>mdi-wrench</v-icon>
                <span>Upravit zákaznícké údaje</span>
              </v-btn>
        </v-card-actions>

        <v-card-actions>
              <v-btn
                color="red"
                rounded
                outlined
                @click="show_pass ++"
              >
                <v-icon left>mdi-lock-reset</v-icon>
                <span>Vygenerovat nové heslo</span>
              </v-btn>
        </v-card-actions>

        </v-card>

        <v-card class="mt-6">
            <v-app-bar flat color="gray lighten-5">
              <v-toolbar-title class="text-h6 pl-0">
                Dvoufázové ověřování
              </v-toolbar-title>
            </v-app-bar>

            <v-card-actions v-if="udaje.TOTP">
                  <v-btn color="orange" rounded outlined @click="show_reset_totp ++">
                    <v-icon left>mdi-lock-reset</v-icon>
                    <span>Zrušit dvoufázové ověření</span>
                  </v-btn>
            </v-card-actions>

            <v-card-actions v-if="!udaje.TOTP">
                  <v-btn color="orange" rounded outlined @click="show_totp ++">
                    <v-icon left>mdi-lock-reset</v-icon>
                    <span>Nastavit dvoufázové ověření</span>
                  </v-btn>
            </v-card-actions>

            <div class="body-1 font-weight-light pa-5">
                  <p>Dvoufázové ověření je způsob, jak více zabezpečit přihlášení k Vašemu účtu. Kromě jména a hesla při přihlášení vyžaduje zadání i jednorázového kódu. Tento kód získáte po spárování Vaší oblíbené aplikace pro správu jednorázových kódů.</p>
                  <p>Můžete použít například tyto aplikace:
                    <br>
                    <v-icon left>mdi-android</v-icon>
                    <br><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_new">Google Authenticator - Android</a>
                    <br><a href="https://play.google.com/store/apps/details?id=com.twofasapp" target="_new">2FA Authenticator (2FAS) - Android</a>
                    <br><a href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_new">Microsoft Authenticator - Android</a>
                    <br>
                    <v-icon left>mdi-apple</v-icon>
                    <br><a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_new">Google Authenticator - iOS</a>
                    <br><a href="https://apps.apple.com/us/app/2fa-authenticator-2fas/id1217793794" target="_new">2FA Authenticator (2FAS) - iOS</a>
                    <br><a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_new">Microsoft Authenticator - iOS</a>

                  </p>
            </div>
        </v-card>


        </v-col>

        </v-row>

    <p-form-change-uzivatel :zobraz="show_form" :udaje="udaje"></p-form-change-uzivatel>
    <p-form-new-pass :zobraz="show_pass"></p-form-new-pass>
    <p-form-set-totp :zobraz="show_totp" :udaje="udaje"></p-form-set-totp>
    <p-form-reset-totp :zobraz="show_reset_totp" :udaje="udaje"></p-form-reset-totp>
  </div>
</template>

<script>

  export default {
    name: 'UzivatelView',
    data: () => ({
        show_form: 0,
        show_pass: 0,
        show_totp: 0,
        show_reset_totp: 0,
        loading: true,
        udaje: {}
    }),

    mounted() {
      this.loadUdaje()
    },

    methods: {
        reload() {
            this.loadUdaje()
        },

        loadUdaje() {
            let url = process.env.VUE_APP_API_URL + "/uzivatel"
            let options = {
                credentials: 'include',
                headers: {
                'Authorization': localStorage.getItem("token"),
                },
            }
            fetch(url, options)
                .then(res => {
                if (res.status === 401) {
                    this.$root.$emit('neprihlasen')
                    throw new Error("Uživatel odhlášen")
                }
                if (res.status !== 200) {
                    throw new Error("Chyba zpracování požadavku")
                }
                return res.json()
                })
                .then(dat => {
                // if (dat.itv) dat.itv.sort((a,b) => {if (a.Stav < b.Stav) return 1; else return -1})
                this.udaje = dat.uzivatel
                this.loading = false
                })
                .catch((err) => {
                    this.$root.$emit('chyba', err.message)
                })
        },

    }

  }
</script>
