import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import UserName from '@/components/UserName'
import FormLogin from '@/components/FormLogin.vue'
import FormRenewPass from '@/components/FormRenewPass.vue'
import FormRegister from '@/components/FormRegister.vue'
import FormTOTP from '@/components/FormTOTP.vue'
import FormChangeInternet from '@/components/FormChangeInternet.vue'
import FormChangeITV from '@/components/FormChangeITV.vue'
import FormChangeUzivatel from '@/components/FormChangeUzivatel.vue'
import FormNewPass from '@/components/FormNewPass.vue'
import FormSetTOTP from '@/components/FormSetTOTP.vue'
import FormResetTOTP from '@/components/FormResetTOTP.vue'

// moje komponenty p jako portal
Vue.component('p-username', UserName)
Vue.component('p-form-login', FormLogin)
Vue.component('p-form-renewpass', FormRenewPass)
Vue.component('p-form-register', FormRegister)
Vue.component('p-form-totp', FormTOTP)
Vue.component('p-form-change-internet', FormChangeInternet)
Vue.component('p-form-change-itv', FormChangeITV)
Vue.component('p-form-change-uzivatel', FormChangeUzivatel)
Vue.component('p-form-new-pass', FormNewPass)
Vue.component('p-form-set-totp', FormSetTOTP)
Vue.component('p-form-reset-totp', FormResetTOTP)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
